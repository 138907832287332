import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import rootReducer from '../rootReducer';
import sagas from '../rootSaga';

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const initialState = {};

/*const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    sagaMiddleware,
    routerMiddleware(history)
  )
);

sagaMiddleware.run(sagas);*/

//@ts-ignore
const preloadedState = window.__PRELOADED_STATE__;
 
// Allow the passed state to be garbage-collected
//@ts-ignore
delete window.__PRELOADED_STATE__;

const store = createStore(
  rootReducer,
  preloadedState || initialState,
  applyMiddleware(
    sagaMiddleware,
    routerMiddleware(history)
  )
);

sagaMiddleware.run(sagas);
 
// Tell react-snap how to save Redux state
//@ts-ignore
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});

export default store;