import { put, takeLatest, all } from 'redux-saga/effects';
import {
  tagsCommon,
  messagesCommon,
  messagesSuccessAction,
  messagesErrorAction,
  tagsSuccessAction,
  tagsErrorAction,
  filterMessagesSuccessAction,
  filterMessagesErrorAction,
} from './actions';
import { messagesService } from '../../services';


function* tags() {
  try {
    const data = yield messagesService.getTags();
    yield put(tagsSuccessAction(data));
  } catch (e) {
    yield put(tagsErrorAction(e));
  }
}

function* messages() {
  try {
    const data = yield messagesService.getMessages();
    yield put(messagesSuccessAction(data?.response));
  } catch (e) {
    yield put(messagesErrorAction(e));
  }
}

function* filterMessages(body: any) {
  try {
    const data = yield messagesService.searchMessages(body.data);
    yield put(filterMessagesSuccessAction(data));
  } catch (e) {
    yield put(filterMessagesErrorAction(e));
  }
}

export function* watchMessages() {
  yield all([
    takeLatest(tagsCommon.TAGS_REQUEST, tags),
    takeLatest(messagesCommon.MESSAGES_REQUEST, messages),
    takeLatest(messagesCommon.FILTER_MESSAGES_REQUEST, filterMessages),
  ]);
}
