import React, {useCallback, useEffect, useState} from 'react';
import { RenderTabItems } from '../Plans/component';
import { Link } from 'react-router-dom';
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
  t: any;
  location: any;
  history: any;
  feedback: any;
  sendFeedback(data: object): void;
  getVisibility(): boolean;
  setVisibility(visibility: boolean): void;
}

export const Feedback = ({
  t,
  location,
  history,
  feedback,
  sendFeedback,
  getVisibility,
  setVisibility
}: Props) => {

    const recaptchaRef = React.createRef();

  const [name, setName] = useState('');
  const [isNameValid, setNameValid] = useState(true);
  const [nameError, setNameError] = useState<string|undefined>();
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState<string|undefined>();
  const [theme, setTheme] = useState('');
  const [isThemeValid, setThemeValid] = useState(true);
  const [themeError, setThemeError] = useState<string|undefined>();
  const [text, setText] = useState('');
  const [isTextValid, setTextValid] = useState(true);
  const [textError, setTextError] = useState<string|undefined>();
  const [token, setToken] = useState<string|undefined>();
  const [isTokenValid, setTokenValid] = useState(true);
  const [tokenError, setTokenError] = useState<string|undefined>();
  const { i18n } = useTranslation();

  const validateEmail = (email: string) => {
    return (/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        .test(email));
  };

  return (
      <Modal 
          centered
          width={800}
          title={t('feedback.title')}
          visible={getVisibility()}
          bodyStyle={{padding: '1em 24px!important'}}
          okText={t('feedback.title1')} //TODO: Translate
          cancelText={t('feedback.title2')}//TODO: Translate
          okType={'primary'}
          okButtonProps={{style: {borderRadius: '9999px', marginBottom: '0.6em', background: '#FF3535', border: 'none', height: '45px', width: '140px',color: 'white', fontSize: '18px'}, disabled: (!isNameValid || !isEmailValid || !isThemeValid || !isTextValid || !isTokenValid)}}
          cancelButtonProps={{danger: true, style: {borderRadius: '9999px', marginBottom: '0.6em', border: 'none', height: '45px', width: '140px', background: '#fff', color: '#5A5A5A', fontSize: '18px'}}}
          onOk={() => {
            let isError = false;
            if(!name) {
              setNameValid(false);
              setNameError(t('feedback.notification1'))//TODO: Translate
              isError = true;
            }

            if(name.length <= 0) {
              setNameValid(false);
              setNameError(t('feedback.notification1'))//TODO: Translate
              isError = true;
            }

            if(!email) {
              setEmailValid(false);
              setEmailError(t('feedback.notification2'))//TODO: Translate
              isError = true;
            }

            if(email.length <= 0) {
              setEmailValid(false);
              setEmailError(t('feedback.notification2'))//TODO: Translate
              isError = true;
            }

            if(!validateEmail(email)) {
              setEmailValid(false);
              setEmailError(t('feedback.notification3'))//TODO: Translate
              isError = true;
            }

            if(!theme) {
              setThemeValid(false);
              setThemeError(t('feedback.notification4'))//TODO: Translate
              isError = true;
            }

            if(theme.length <= 0) {
              setThemeValid(false);
              setThemeError(t('feedback.notification4'))//TODO: Translate
              isError = true;
            }

            if(!text) {
              setTextValid(false);
              setTextError(t('feedback.notification5'))//TODO: Translate
              isError = true;
            }

            if(text.length <= 0) {
              setTextValid(false);
              setTextError(t('feedback.notification5'))//TODO: Translate
              isError = true;
            }

            if(!token) {
              setTokenValid(false);
              setTokenError(t('feedback.notification6'))//TODO: Translate
              isError = true;
            }

            if(token && token.length <= 0) {
              setTokenValid(false);
              setTokenError(t('feedback.notification6'))//TODO: Translate
              isError = true;
            }

            if(!isError) {
              sendFeedback({
                theme: theme,
                name: name,
                email: email,
                message: text,
                recaptcha_token: token,
                lang: i18n.language ? i18n.language : 'en'
              });

              setName('');
              setNameError(undefined);
              setEmail('');
              setEmailError(undefined);
              setTheme('');
              setThemeError(undefined);
              setText('');
              setTextError(undefined);
              setToken(undefined);
              setTokenError(undefined);

              // @ts-ignore
                recaptchaRef.current.reset();
              setVisibility(false);
            }
          }}
          confirmLoading={feedback.loading}
          onCancel={() => {
            setName('');
            setNameValid(true);
            setEmail('');
            setEmailValid(true);
            setTheme('');
            setThemeValid(true);
            setText('');
            setTextValid(true);
            setToken(undefined);
            setTokenValid(true);
            setNameError(undefined);
            setEmailError(undefined);
            setThemeError(undefined);
            setTextError(undefined);
            setTokenError(undefined);
              // @ts-ignore
              recaptchaRef.current.reset();
            setVisibility(false);
          }}
      >
        <Form
          layout="vertical"
        >
          <label className='feedback-title'>{t('feedback.text1')}<a href="https://billing.net-ray.com/submitticket.php" target="_blank">{t('feedback.text2')}</a><p>{t('feedback.text3')}</p></label>
          <Form.Item
            label={t('feedback.label1')}//TODO: Translate
            validateStatus={isNameValid ? 'success' : 'error'}
            help={nameError || ''}
          >
            <Input
                placeholder={t("feedback.label1")}//TODO: Translate
                value={name}
                onChange={(value) => {
                    setNameValid(true);
                    setNameError(undefined);
                    let name = value.target.value;
                    if(!name) {
                        setNameValid(false);
                        setNameError(t('feedback.notification1'))//TODO: Translate
                    }

                    if(name.length <= 0) {
                        setNameValid(false);
                        setNameError(t('feedback.notification1'))//TODO: Translate
                    }
                  setName(name)
                }}
            />
          </Form.Item>
          <Form.Item
              label={t('feedback.label2')}//TODO: Translate
              validateStatus={isEmailValid ? 'success' : 'error'}
              help={emailError || ''}
          >
            <Input
                placeholder={t("feedback.label2")}//TODO: Translate
                value={email}
                onChange={(value) => {
                    setEmailValid(true);
                    setEmailError(undefined);
                    let email = value.target.value;
                    if(!email) {
                        setEmailValid(false);
                        setEmailError(t('feedback.notification2'));//TODO: Translate
                    }

                    if(email.length <= 0) {
                        setEmailValid(false);
                        setEmailError(t('feedback.notification2'));//TODO: Translate
                    }

                    if(!validateEmail(email)) {
                        setEmailValid(false);
                        setEmailError(t('feedback.notification3'));//TODO: Translate
                    }
                  setEmail(email)
                }}
            />
          </Form.Item>
          <Form.Item
              label={t("feedback.label3")}//TODO: Translate
              validateStatus={isThemeValid ? 'success' : 'error'}
              help={themeError || ''}
          >
            <Input
                placeholder={t("feedback.label4")}//TODO: Translate
                value={theme}
                onChange={(value) => {
                    setThemeValid(true);
                    setThemeError(undefined);
                    let theme = value.target.value;
                    if(!theme) {
                        setThemeValid(false);
                        setThemeError(t('feedback.notification4'));//TODO: Translate
                    }

                    if(theme.length <= 0) {
                        setThemeValid(false);
                        setThemeError(t('feedback.notification4'));//TODO: Translate
                    }
                  setTheme(theme)
                }}
            />
          </Form.Item>
          <Form.Item
              label={t("feedback.label5")}//TODO: Translate
              validateStatus={isTextValid ? 'success' : 'error'}
              help={textError || ''}
          >
            <Input.TextArea
                placeholder={t("feedback.label6")}//TODO: Translate
                value={text}
                rows={6}
                onChange={(value) => {
                    setTextValid(true);
                    setTextError(undefined);
                    let text = value.target.value;
                    if(!text) {
                        setTextValid(false);
                        setTextError(t('feedback.notification4'));//TODO: Translate
                    }

                    if(text.length <= 0) {
                        setTextValid(false);
                        setTextError(t('feedback.notification4'));//TODO: Translate
                    }
                  setText(text)
                }}
            />
          </Form.Item>
          <Form.Item
              className='captcha-container'
              // label={'CAPTCHA'}//TODO: Translate
              validateStatus={isTokenValid ? 'success' : 'error'}
              help={tokenError || ''}
          >
              <ReCAPTCHA
                  style={{display: 'flex', justifyContent: 'center'}}
                  sitekey="6LcIZ3AgAAAAAB6GMgWCnyYORHdAudUr5ua8TWiy"
                  ref={recaptchaRef}
                  onChange={(value: any) => {
                      setTokenValid(true);
                      setTokenError(undefined);
                      if(!value) {
                          setToken(undefined);
                          setTokenValid(false);
                          setTokenError(t('feedback.notification6'))//TODO: Translate
                      }
                      else {
                          setToken(value);
                          setTokenValid(true);
                          setTokenError(undefined);
                      }
                  }}
              />
          </Form.Item>
        </Form>
      </Modal>
  );
};
