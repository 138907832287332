import { Select } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import config from '../../config/index'

const tableHead = [
  'comparison-of-plans.table.tarif',
  'comparison-of-plans.table.price',
  'comparison-of-plans.table.vcpu',
  'comparison-of-plans.table.ram',
  'comparison-of-plans.table.storage',
  'comparison-of-plans.table.available-os',
  'comparison-of-plans.table.support',
  'comparison-of-plans.table.chanel',
  'comparison-of-plans.table.trafic',
]

const minimalTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Minimal',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/minimal',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.minimal.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '1 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '2 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '25 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.standart',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '100 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '5 Tb',
    purchaseLink: '',
  },
]

const economyTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Economy',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/economy',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.economy.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '2 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '2 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '25 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.standart',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '100 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '5 Tb',
    purchaseLink: '',
  },
]

const littleBitTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Little Bit',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/little-bit',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.littleBit.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '1 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '4 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '40 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Windows \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.standart',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '100 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '10 Tb',
    purchaseLink: '',
  },
]

const optimalTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Optimal',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/optimal',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.optimal.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '2 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '4 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '40 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Windows \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.standart',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '100 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '10 Tb',
    purchaseLink: '',
  },
]

const littleBitMoreTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Little Bit More',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/little-bit-more',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.littleBitMore.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '2 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '6 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '60 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Windows \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.standart',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '100 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '∞',
    purchaseLink: '',
  },
]

const largestTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'The Largest',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/the-largest',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.largest.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '2 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '8 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '60 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Windows \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.hight',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '200 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '∞',
    purchaseLink: '',
  },
]


const ultimateTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Ultimate',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/ultimate',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: `$${config.price.ultimate.price}`,
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '3 vCPU',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '8 Gb RAM',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '80 Gb SSD',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Windows \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.hight',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '200 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '∞',
    purchaseLink: '',
  },
]

const yourOptionTarif = [
  {
    isBold:false,
    isPurchaseButton: true,
    isMultiLanguage: false,
    value: 'Your Option',
    purchaseLink: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/your-option',
  },
  {
    isBold:true,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'plans.customprice',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '-',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '-',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: '-',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: false,
    value: 'Ubuntu \n Debian \n Windows \n Kali Linux',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: 'comparison-of-plans.priority.hight',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '200 mbps',
    purchaseLink: '',
  },
  {
    isBold:false,
    isPurchaseButton: false,
    isMultiLanguage: true,
    value: '∞',
    purchaseLink: '',
  },
]
const tarifs = {
  minimalTarif,
  economyTarif,
  littleBitTarif,
  optimalTarif,
  littleBitMoreTarif,
  largestTarif,
  ultimateTarif,
  yourOptionTarif
}

const ComparisonOfPlans=()=>{
  const { t } = useTranslation()
  const [currentHoverRow, setCurrentHoverRow] = useState('')
  const [selectedField, setSelectedField] = useState(0)

  return(
    <main className='wrapper comparison-of-plans'>
      <section>
        <div className='title-container'>
          <div className='comparison-link'/>
          <h1>{t('comparison-of-plans.title')}</h1>
          <a href="/shop" className='comparison-link'>
            {t('comparison-of-plans.choose-plan')}
            {/* <img src='../resources/img/iconamoon_arrow-down-2-light.svg'/> */}
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="iconamoon:arrow-down-2-light">
                <path id="Vector" d="M11.6667 19.8333L17.5001 14L11.6667 8.16665" stroke="#0085FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
            </svg>
          </a>
        </div>

        <div className='field-selector'>
          <Select className="ant-select" value={selectedField} onChange={(value)=>{setSelectedField(value)}}>
            {tableHead.filter(el=> ![tableHead[0], tableHead[1]].includes(el)).map((el, index)=>
              <Select.Option 
                key={el} 
                value={index}
              >
                {t(el)}
              </Select.Option>
            )}
        
          </Select>
        </div>

        <div className='content'>

          <div className='table'>
            {
              tableHead.map((el, index)=>
                <div 
                  key={el} 
                  className={`${![0,1,selectedField+2].includes(index)?'hide-cell':''}`}
                >
                  {t(el)}
                </div>
              )
            }
            {Object.values(tarifs).map((tarif, indexTarif)=>
              tarif.map((el, index)=>
                <div 
                  key={`${indexTarif}-${index}`} 
                  className={`
                    cell 
                    ${el.isPurchaseButton&&'purchase-btn relative-container'}
                    ${el.isBold&&'bold-700'}
                    ${![0,1,selectedField+2].includes(index)?'hide-cell':'round-ride-side'}`
                  }
                  onMouseEnter={()=>setCurrentHoverRow(`${indexTarif}`)}
                  onMouseLeave={()=>setCurrentHoverRow('')}
                >
                  <div style={{textAlign: 'center'}}>
                    {el.isMultiLanguage&&t(el.value)}
                    {!el.isMultiLanguage&&(el.value.split('\n').map(os=><div key={os}>{os}</div>))}
                  </div>
                  {el.isPurchaseButton&&
                    <Link 
                      to={{pathname: el.purchaseLink}} 
                      className={`
                        purchase-vps 
                        ${currentHoverRow===`${indexTarif}`?'active-purchase-btn':'inactive-purchase-btn'}`}
                      target='_blank'
                      // onClick={event=>event.preventDefault()}
                    >
                      {t('plans.buyvps')}
                    </Link>
                  }
                </div>
              )
            )
          }
          </div>
        </div>
        <div 
          style={
            {
              display: 'flex', 
              justifyContent: 'center', 
              width: '95%', 
              textAlign: 'center',
              transform: 'translateY(-120px)'
            }
          } 
          className='container'
        >
          <img src="../resources/img/mdi_shield-lock.svg" alt="shield_icon"/>
          <span>{t('plans.security-payment')}</span>
        </div>
      </section>
    </main>
  )
}

export default ComparisonOfPlans