import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/translation';
import ru from './ru/translation';
import de from './de/translation';
import uk from './uk/translation';
import fr from './fr/translation';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      ru: ru,
      de: de,
      uk: uk,
      fr: fr
    },
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
