import React, {useState} from 'react';
import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {Feedback} from "../Feedback";
import Banner from '../Banner/component';

interface Props {
  children: any;
}

const MainLayoutContainer = ({ children }: Props) => {
    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const getFeedbackVisible = () : boolean => feedbackVisible;
    const setFeedbackVisibility = (visible: boolean) : void => setFeedbackVisible(visible);
    return (
    <>
      <Header />
        {children}
        <Fab
            style={{
              display: (feedbackVisible ? 'none' : ''),
              bottom: '50%',
              right: '-70px',
              transform: 'rotate(-90deg)',
            }}
            mainButtonStyles={{
              margin: '0px',
              width: '120px', 
              height: '40px',
              fontWeight: 500,
              borderTopLeftRadius: '15px', 
              borderTopRightRadius: '15px', 
              borderBottomLeftRadius:'0px', 
              borderBottomRightRadius:'0px', 
              boxShadow: 'none',
              backgroundColor: '#FF8B00',
              color: '#fff',
              fontSize: '16px',
            }}
            icon={
                'Feedback'
            }
            alwaysShowTitle={true}
            onClick={() => {setFeedbackVisible(true)}}
        />
        {/* <Banner /> */}
        <Feedback getVisibility={getFeedbackVisible} setVisibility={setFeedbackVisibility}/>
      <Footer setVisibility={setFeedbackVisibility} />
    </>
  );
};

export { MainLayoutContainer };
