import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './styles.module.css';

interface Props {
  t: any;
  location: any;
  history: any;
  messages: any;
  tags: any;
  getMessages(): void;
  getTags(): void;
  searchMessages(obj: object): void;
}

export const Partners = ({
  t,
  location,
  history,
  messages,
  tags,
  getMessages,
  getTags,
  searchMessages
}: Props) => {
  const goToPlans = () => {
    return history.push('/shop');
  }

  return (
    <main>
      <div className="wrapper home">
        <p className="parttexth1">{t('footer.partners')}</p>

        <div className="content">
          

          <div className="left-cont">
            <a href='https://vds.menu/company.php?id=463' target='_blank' className="left-cont">
              <div className="title">
                <img height="65" className='img-hosting' src='https://vds.menu/images/url/fvds.png'/>
              </div>
              <div className="desc1">
                {t('partners.hostdescr2')}
              </div>
            </a>
          </div>

          <div className="left-cont">
            <a href='https://hostgid.net/cat/hosters/netray' target='_blank' className="left-cont">
              <div className="title">
                <p className='img-hosting'>Hostgid</p>
              </div>
              <div className="desc1">
                {t('partners.hostdescr3')}
              </div>
            </a>
          </div>

          <div className="left-cont">
            <a href='https://www.hostsearch.com/company-info/netray.asp' target='_blank' className="left-cont">
              <div className="title">
                <p className='img-hosting'>Hostsearch</p>
              </div>
              <div className="desc1">
                {t('partners.hostdescr4')}
              </div>
            </a>
          </div>


          <div className="left-cont">
            <a href='https://hostadvice.com/hosting-company/netray-reviews/#main-info' target='_blank' className="left-cont">
              <div className="title">
                <img height="70" className='img-hosting' src='https://hostadvice.com/wp-content/themes/bestwh/v3/dist/img/logo-fox.png'/>
              </div>
              <div className="desc1">
                {t('partners.hostdescr8')}
              </div>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
