import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';
import { Partners } from './component';
import { useHistory } from 'react-router-dom';
import {
  messagesRequestAction,
  filterMessagesRequestAction,
  tagsRequestAction
} from './actions';

const PartnersContainer = () => {
  const history = useHistory();
  const location = useLocation(),
        dispatch = useDispatch(),
        messages = useMappedState((state: any) => state.messages),
        tags = useMappedState((state: any) => state.tags);

  const { t } = useTranslation();

  return (
    <Partners
      location={location}
      messages={messages}
      history={history}
      t={t}
      tags={tags}
      getMessages={() => { dispatch(messagesRequestAction()) }}
      getTags={() => { dispatch(tagsRequestAction()) }}
      searchMessages={(data) => { dispatch(filterMessagesRequestAction(data)) }}
    />
  );
};

export { PartnersContainer as Partners };
