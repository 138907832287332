import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Terms } from './component';

const TermsContainer = () => {
  const location = useLocation(),
        dispatch = useDispatch(),
        history = useHistory();

    const { t } = useTranslation();

  return (
    <Terms
      location={location}
      history={history}
      t={t}
    />
  );
};

export { TermsContainer as Terms };
