import { messagesCommon, tagsCommon } from './actions';
import { BaseAction, InitialState } from '../../types';

const initialState: InitialState = {
  data: [],
  meta: {},
  loading: false
};

export const messages = (state = initialState, action: BaseAction) => {
  switch (action.type) {
    case messagesCommon.MESSAGES_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case messagesCommon.MESSAGES_SUCCESS:
      return {
        data: action.data,
        loading: false
      };

    case messagesCommon.FILTER_MESSAGES_REQUEST:
      return {
        data: state.data,
        meta: state.meta,
        loading: true
      };

    case messagesCommon.FILTER_MESSAGES_SUCCESS:
      return {
        data: action.data?.response,
        meta: action.data?.meta,
        loading: false
      };

    default:
      return state;
  }
};

export const tags = (state = initialState, action: BaseAction) => {
  switch (action.type) {
    case tagsCommon.TAGS_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case tagsCommon.TAGS_SUCCESS:
      return {
        data: action.data,
        loading: false
      };

    default:
      return state;
  }
};
