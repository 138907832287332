import { BaseAction } from '../../types';

export const settingsCommon = {
  CHANGE_PWD_REQUEST: 'CHANGE_PWD_REQUEST',
  CHANGE_PWD_SUCCESS: 'CHANGE_PWD_SUCCESS',
  CHANGE_PWD_ERROR: 'CHANGE_PWD_ERROR',
};

export const changePwdRequestAction = (data: {}): BaseAction => ({
  type: settingsCommon.CHANGE_PWD_REQUEST,
  data
});

export const changePwdSuccessAction = (data: []): BaseAction => ({
  type: settingsCommon.CHANGE_PWD_SUCCESS,
  data
});

export const changePwdErrorAction = (error: {}): BaseAction => ({
  type: settingsCommon.CHANGE_PWD_ERROR,
  data: error
});
