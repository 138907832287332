import { BaseAction } from '../../types';

export const messagesCommon = {
  MESSAGES_REQUEST: 'MESSAGES_REQUEST',
  MESSAGES_SUCCESS: 'MESSAGES_SUCCESS',
  MESSAGES_ERROR: 'MESSAGES_ERROR',
  FILTER_MESSAGES_REQUEST: 'FILTER_MESSAGES_REQUEST',
  FILTER_MESSAGES_SUCCESS: 'FILTER_MESSAGES_SUCCESS',
  FILTER_MESSAGES_ERROR: 'FILTER_MESSAGES_ERROR',
};

export const tagsCommon = {
  TAGS_REQUEST: 'TAGS_REQUEST',
  TAGS_SUCCESS: 'TAGS_SUCCESS',
  TAGS_ERROR: 'TAGS_ERROR'
}

export const tagsRequestAction = (): BaseAction => ({
  type: tagsCommon.TAGS_REQUEST
});

export const tagsSuccessAction = (data: []): BaseAction => ({
  type: tagsCommon.TAGS_SUCCESS,
  data
});

export const tagsErrorAction = (error: {}): BaseAction => ({
  type: tagsCommon.TAGS_ERROR,
  data: error
});

export const messagesRequestAction = (): BaseAction => ({
  type: messagesCommon.MESSAGES_REQUEST
});

export const messagesSuccessAction = (data: []): BaseAction => ({
  type: messagesCommon.MESSAGES_SUCCESS,
  data
});

export const messagesErrorAction = (error: {}): BaseAction => ({
  type: messagesCommon.MESSAGES_ERROR,
  data: error
});

export const filterMessagesRequestAction = (data: {}): BaseAction => ({
  type: messagesCommon.FILTER_MESSAGES_REQUEST,
  data
});

export const filterMessagesSuccessAction = (data: []): BaseAction => ({
  type: messagesCommon.FILTER_MESSAGES_SUCCESS,
  data
});

export const filterMessagesErrorAction = (error: {}): BaseAction => ({
  type: messagesCommon.FILTER_MESSAGES_ERROR,
  data: error
});
