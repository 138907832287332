import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Select} from "antd";

interface Props {
  location: any;
  t: any;
  i18n: any;
  setVisibility(visibility: boolean): void;
}

export const Footer = ({
  location,
  t,
  i18n,
  setVisibility
}: Props) => {
  const [defLang, setDefLang] = useState<string | undefined>(undefined);

  useEffect(() => {
    setDefLang(i18n.language);
  }, [i18n.language])

  const handleLangChange = (e: any) => {
    const targetValue = e;

    i18n.changeLanguage(targetValue);
  };

  return (
    <footer>
      <div className="footer">
        <div className="wrapper footer-block">
          <div className="info foot">
            <div className="left">
              <Link to={'/home'}>{t('footer.home')}</Link>
              <Link to={'/shop'}>{t('footer.plans')}</Link>
              <Link to={'/payment/cards'}>{t('footer.payments')}</Link>
              <Link to={'/faq'}>{t('footer.faq')}</Link>
              <Link to={'/terms'}>{t('footer.terms')}</Link>
              <Link to={'/partners'}>{t('footer.partners')}</Link>
              <Link to="#" onClick={()=> {setVisibility(true)}}>{t('footer.contacts')}</Link>
              {/* <div className="ant-select-mobile">
                <Select id="lang" value={defLang ? defLang : 'en'} onChange={handleLangChange}>
                  <Select.Option value="en">English</Select.Option>
                  <Select.Option value="ru">Русский</Select.Option>
                  <Select.Option value="de">Deutsch</Select.Option>
                  <Select.Option value="uk">Українська</Select.Option>
                  <Select.Option value="fr">Français</Select.Option>
                </Select>
              </div> */}
            </div>

            {/*<div className="right">*/}
            {/*  <Link to={'/rules'}>{t('footer.locations')}</Link>*/}
            {/*  <Link to={'/rules'}>{t('footer.other')}</Link>*/}
            {/*  <Link to={'/rules'}>{t('footer.download')}</Link>*/}
            {/*  <Link to={'/contacts'}>{t('footer.source')}</Link>*/}
            {/*  <Link to={'/contacts'}>{t('footer.partners')}</Link>*/}
            {/*  <Link to={'/contacts'}>{t('footer.virtual')}</Link>*/}
            {/*  <Link to={'/contacts'}>{t('footer.linky')}</Link>*/}
            {/*</div>*/}
          </div>
          
          <div className="logo">
            <a href="/"><img src="../resources/img/footer_logo.svg" alt="logo"/></a>
          </div>
        </div>
      </div>
      <div className='wrapper sub-footer'>
        <div style={{width: "96%", height: "78px", display: "flex", alignItems: "center", position: "relative", justifyContent: "space-between"}}>
          <div style={{position: 'relative'}}>
              <img src="../resources/img/gis_earth.svg" alt="earth" className="language-icon" style={{position: 'absolute', top: '50%', left:'8px', width: '18px', paddingRight: "4px"}}/>
                <Select id="lang" value={defLang ? defLang : 'en'} onChange={handleLangChange} style={{marginRight: '0px !important'}}>
                  <Select.Option value="en">English</Select.Option>
                  <Select.Option value="ru">Русский</Select.Option>
                  <Select.Option value="de">Deutsch</Select.Option>
                  <Select.Option value="uk">Українська</Select.Option>
                  <Select.Option value="fr">Français</Select.Option>
                </Select>
            </div>
        </div>
        <div className='support-language'>
          <p className='support-contact' style={{textAlign: "left", maxWidth: "600px", width: "100%", paddingRight: "15px"}}>Ви можете написати нам через <a style={{color: "#0085FF"}}>форму зворотнього</a> зв'язку або на email <a style={{color: "#0085FF"}} type='email' href='mailto:support@net-ray.com'>support@net-ray.com</a>.</p>
        </div>
        <div style={{display: "flex", justifyContent: "start", width: "100%"}}>
          <p className="copyright">{t('footer.rights')}</p>
        </div>
      </div>
    </footer>
  );
};


