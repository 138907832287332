import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  sourcesCommon,
  sourcesSuccessAction,
  sourcesErrorAction,
  addSourceSuccessAction,
  addSourceErrorAction,
  updateSourceEnablingSuccessAction,
  updateSourceEnablingErrorAction,
  removeSourceSuccessAction,
  removeSourceErrorAction,
  editSourceSuccessAction,
  editSourceErrorAction
} from './actions';
import { sourcesService } from '../../services';

function* sources() {
  try {
    const data = yield sourcesService.getSources();
    yield put(sourcesSuccessAction(data));
  } catch (e) {
    yield put(sourcesErrorAction(e));
  }
}

function* addSources(body: any) {
  try {
    const data = yield sourcesService.addSource(body.data);

    if (data.meta.status === 'Ok') {
      yield put(addSourceSuccessAction({
        data: body.data,
        meta: data.meta
      }));
    } else {
      yield put(addSourceErrorAction(data.meta));
    }
  } catch (e) {
    yield put(addSourceErrorAction(e));
  }
}

function* updateSourceEnabling(body: any) {
  try {
    const data = yield sourcesService.updateSourceEnabling(body.data.id, body.data.body);
    if (data.meta.status === 'Ok') {
      yield put(updateSourceEnablingSuccessAction(body.data));
    }
  } catch (e) {
    yield put(updateSourceEnablingErrorAction(e));
  }
}

function* removeSource(req: any) {
  try {
    const data = yield sourcesService.removeSource(req.data);
    if (data.meta.status === 'Ok') {
      yield put(removeSourceSuccessAction(req.data));
    }
  } catch (e) {
    yield put(removeSourceErrorAction(e));
  }
}

function* editSource(body: any) {
  try {
    const data = yield sourcesService.editSource(body.data.id, body.data.body);
    if (data.meta.status === 'Ok') {
      yield put(editSourceSuccessAction(body.data));
    }
  } catch (e) {
    yield put(editSourceErrorAction(e));
  }
}

export function* watchSources() {
  yield all([
    takeLatest(sourcesCommon.SOURCES_REQUEST, sources),
    takeLatest(sourcesCommon.ADD_SOURCE_REQUEST, addSources),
    takeLatest(sourcesCommon.UPDATE_SOURCE_ENABLING_REQUEST, updateSourceEnabling),
    takeLatest(sourcesCommon.REMOVE_SOURCE_REQUEST, removeSource),
    takeLatest(sourcesCommon.EDIT_SOURCE_REQUEST, editSource),
  ]);
}
