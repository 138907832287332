export default {
  feedbackUrl: 'https://back.net-ray.com',
  apiUrl: 'https://backend.scra-ping.com',
  price:{
    minimal:{
      price: 7.95,
      discount: 0,
      newPrice: 0
    },
    economy:{
      price: 8.95,
      discount: 0,
      newPrice: 0
    },
    littleBit:{
      price: 10.95,
      discount: 0,
      newPrice: 0
    },
    optimal:{
      price: 12.95,
      discount: 0,
      newPrice: 0
    },
    littleBitMore:{
      price: 14.95,
      discount: 0,
      newPrice: 0
    },
    largest:{
      price: 17.95,
      discount: 0,
      newPrice: 0
    },
    ultimate:{
      price: 20.95,
      discount: 0,
      newPrice: 0
    }
  }
}