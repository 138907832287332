import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { RenderLinks } from '../Header/component';
import Select, { OptionsOrGroups, StylesConfig } from 'react-select';
import config from '../../config/index'

interface Props {
  t: any;
  i18n: any;
  location: any;
  history: any;
  sources: any;
  getSources(): void;
  updateSourceEnabling(id: string, body: any): void;
  removeSource(id: string): void;
  editSource(id: string, body: any): void;
}

enum en_countries{
  'germany' = 'germany',
  'ukraine' = 'ukraine',
  'poland' = 'poland'
}

export const Plans = ({
  t,
  i18n,
  location,
  history,
}: Props) => {

  const minimalItemsName = 'Minimal';
  const minimalItemsDesc = [
    {
      label: t('plans.desc1-1'),
      value: '1 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc4'),
      value: '2 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '25 Gb SSD',
    },
  ];
  const minimalItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.minimal.price,
      price: config.price.minimal.price,
      newPrice: config.price.minimal.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/minimal'
    }
  ];

  // economy
  const economy = 'Economy';
  const economyItemsDesc = [
    {
      label: t('plans.desc1-1'),
      value: '2 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc2'),
      value: '2 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '25 Gb SSD',
    },
  ];
  const economyItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.economy.price,
      price: config.price.economy.price,
      newPrice: config.price.economy.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/economy'
    }
  ];

  const littleBitName = 'Little Bit';
  const littleBitDesc = [
    {
      label: t('plans.desc1-2'),
      value: '1 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc2'),
      value: '4 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '40 Gb SSD',
    },
  ];
  const littleBitItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.littleBit.price,
      price: config.price.littleBit.price,
      newPrice: config.price.littleBit.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/little-bit'

    }
  ];

  // optimal
  const optimal = 'Optimal';
  const optimalItemsDesc = [
    {
      label: t('plans.desc1-2'),
      value: '2 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc4'),
      value: '4 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '40 Gb SSD',
    },
  ];
  const optimalItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.optimal.price,
      price: config.price.optimal.price,
      newPrice: config.price.optimal.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/optimal'
    }
  ];
  const littleBitMoreName = 'Little Bit More';
  const littleBitMoreDesc = [
    {
      label: t('plans.desc1'),
      value: '2 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc4'),
      value: '6 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '60 Gb SSD',
    },
  ];
  const littleBitMoreItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.littleBitMore.price,
      price: config.price.littleBitMore.price,
      newPrice: config.price.littleBitMore.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/little-bit-more'
    }
  ];

  const theLargestName = 'The Largest';
  const theLargestDesc = [
    {
      label: t('plans.desc1'),
      value: '2 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc4'),
      value: '8 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '60 Gb SSD',
    },
    {
      label: t('plans.desc8'),
      value: '',
    },
  ];
  const theLargestItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.largest.price,
      price: config.price.largest.price,
      newPrice: config.price.largest.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/the-largest'
    }
  ];

  const customName = 'Your Option';
  const customDesc = [
    {
      label: t('plans.desc1'),
      value: t('plans.desc7'),
    },
    {
      minLabel: t('plans.desc4'),
      label: t('plans.desc4'),
      value: t('plans.desc5'),
    },
    {
      label: t('plans.desc3'),
      value: t('plans.desc6'),
    },
    {
      label: t('plans.desc8'),
      value: '',
    },
  ];
  const customItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      newPrice: 0,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/your-option'
    }
  ];

  //ultimate
  const ultimateName = 'Ultimate';
  const ultimateDesc = [
    {
      label: t('plans.desc1'),
      value: '3 vCPU',
    },
    {
      minLabel: t('plans.desc2'),
      label: t('plans.desc4'),
      value: '8 Gb RAM',
    },
    {
      label: t('plans.desc3'),
      value: '80 Gb SSD',
    },
    {
      label: t('plans.desc8'),
      value: '',
    },
  ];
  const ultimateItems = [
    {
      value: en_countries.ukraine,
      disable: false,
      discount: config.price.ultimate.price,
      price: config.price.ultimate.price,
      newPrice: config.price.ultimate.newPrice,
      link: 'https://billing.net-ray.com/index.php?rp=/store/ukraine/ultimate'

    }
  ];

  const [current, setCurrent] = useState(customItems[customItems.length-1]);


  const paymentUrlLanguage = {
    'ru': 'russian',
    'en': 'english',
    'it': 'italian',
    'fr': 'french',
    'de': 'german',
    'spa': 'spanish',
    'tu': 'turkish',
    'ua': 'ukranian'
  };


  const [currentCountry, setCurrentCountry] = useState<en_countries>(en_countries.ukraine)
  const [isOnScreen, setIsOnScreen] = useState(false)

  const constRefCountries = useRef<HTMLDivElement>(null)
  const scrollEvent=()=>{
      if(constRefCountries.current){
        if(constRefCountries.current.getBoundingClientRect().top===0){
          setIsOnScreen(true)
        }else{
          setIsOnScreen(false)
        }
      }
  }
  useEffect(()=>{
    window.addEventListener('scroll', scrollEvent)
    return ()=>{
      window.removeEventListener('scroll', scrollEvent)
    }
  },[])

  const [isHideText, setIsHideText] = useState(false)

  useEffect(()=>{
    //@ts-ignore
    setCurrent(customItems.find((el:any)=>el.value === currentCountry))
  },[currentCountry])
  
  return (
    <main>
      <div className="wrapper plans">
        <div className='title-container'>
          <div className='comparison-link'/>
          <h1>{t('plans.title')}</h1>
          <Link to="/comparison-of-plans" className='comparison-link' id='plans-comparison'>
            {t('plans.compare-plans')}
            {/* <img src='../resources/img/iconamoon_arrow-down-2-light.svg'/> */}
            <div className='comparison-link-img' style={{width: "28px", display: "flex"}}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="iconamoon:arrow-down-2-light">
                <path id="Vector" d="M11.6667 19.8333L17.5001 14L11.6667 8.16665"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
            </svg>
            </div>
          </Link>
        </div>
          
        <div className="content row">
          <div className="content-item wide">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={minimalItemsName} 
              desc={minimalItemsDesc} 
              items={minimalItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={2} 
            />
          </div>
          <div className="content-item narrow">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={economy} 
              desc={economyItemsDesc} 
              items={economyItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={0}
            />
          </div>
          <div className="content-item narrow">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={littleBitName} 
              desc={littleBitDesc} 
              items={littleBitItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={1}
            />
          </div>
          <div className="content-item wide">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={optimal} 
              desc={optimalItemsDesc} 
              items={optimalItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={3}
            />
          </div>
          <div className="content-item wide">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={littleBitMoreName} 
              desc={littleBitMoreDesc} 
              items={littleBitMoreItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={2}
            />
          </div>
          <div className="content-item narrow">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={theLargestName} 
              desc={theLargestDesc} 
              items={theLargestItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={0}
            />
          </div>
          <div className="content-item narrow">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={ultimateName} 
              desc={ultimateDesc} 
              items={ultimateItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={1}
            />
          </div>
          <div className="content-item wide">
            <Tab 
              isHideText={isHideText} 
              t={t} 
              name={customName} 
              desc={customDesc} 
              items={customItems} 
              location={location} 
              history={history} 
              currentCountry={currentCountry} 
              pricePosition={3}
            />
          </div>
          <div style={{width: '95%', textAlign: 'center'}}>
            <img src="../resources/img/mdi_shield-lock.svg" alt="shield_icon"/>
            <span style={{fontSize: "12px"}}>{t('plans.security-payment')}</span>
          </div>
        </div>
      </div>
    </main>
  );
};

export const Tab = ({location, t, history, i18n, name, items, desc, currentCountry, isHideText, pricePosition}: any) => {
  const [current, setCurrent] = useState(items[items.length-1]);

  useEffect(()=>{
    setCurrent(items.find((el:any)=>el.value === currentCountry))
  },[currentCountry])

  const paymentUrlLanguage = {
    'ru': 'russian',
    'en': 'english',
    'it': 'italian',
    'fr': 'french',
    'de': 'german',
    'spa': 'spanish',
    'tu': 'turkish',
    'ua': 'ukranian'
  };

  const getCurrentLang = () => {
    // @ts-ignore
    return paymentUrlLanguage[localStorage.getItem('lng') || 'en'];
  }

  const thirdPositionPriceTemplate = (
    <>
      {
        current.newPrice !== 0 ?
        <div className='discount-label'>
          <img src="../../resources/img/discountLabel.png" alt='discount-label' />
          <span>${current.newPrice}</span>
        </div>
        : <></>
      }
      <div className='card-title'>
        {name}
      </div>
      <ul className='container'>
        {desc.map((item: any, i: number) => {
          return (
            <li className='flex-between' key={i}>
              <span className="label">{item.minLabel ? item.minLabel : item.label}</span>
              <span className="value">{item.value}</span>
            </li>
          )
        })}
      </ul>
      <div className="flex-between container">
        <a 
          href={current && current?.link + `&language=${getCurrentLang()}&billingcycle=annually`} 
          className="purchase-vps"
          // onClick={event=>event.preventDefault()}
        >
          {t('plans.buyvps')}
        </a>
        <div style={{position: 'relative'}}>
          <span className='price'>{(current && current?.price && `$${current.price}`) || `${t('plans.customprice')}`}</span>
          {
            current.newPrice !== 0 ?
              <img src="../../resources/img/discount.svg" alt="discount" className='discount-center'/>
            :
              <></>
          }
        </div>
      </div>
    </>
  )

  const firstPositionPriceTemplate = (
    <>
      {
        current.newPrice !== 0 ?
        <div className='right-discount-label'>
          <img src="../../resources/img/discountLabel.png" alt='discount-label' />
          <span>${current.newPrice}</span>
        </div>
        :
        <></>
      }
      <div className='card-title'>
        {name}
      </div>
      <div className="flex-between container" >
        <div style={{position:'relative'}}>
          <span className='price'>{(current && current?.price && `$${current.price}`) || `${t('plans.customprice')}`}</span>
          {
            current.newPrice !== 0 ?
              <img src="../../resources/img/discount.svg" alt="discount" className='discount-center'/>
            :
              <></>
          }
        </div>
        <ul>
        {desc.map((item: any, i: number) => {
          return (
            <li className='flex-between' key={i}>
              <span className="value text-no-wrap">{item.value}</span>
            </li>
          )
        })}
        </ul>
      </div>
      <div className='container flex-between'>
      <ul>
        {desc.map((item: any, i: number) => {
          return (
            <li className='flex-between' key={i}>
              <span className="label text-no-wrap">{item.label}</span>
            </li>
          )
        })}
        </ul>
        <a 
          href={current && current?.link + `&language=${getCurrentLang()}&billingcycle=annually`} 
          className="purchase-vps"
          // onClick={event=>event.preventDefault()}
          >
            {t('plans.buyvps')}
        </a>
      </div>
    </>
  )

  const secondPositionPriceTemplate = (
    <>
    {
        current.newPrice !== 0 ?
        <div className='discount-label'>
          <img src="../../resources/img/discountLabel.png" alt='discount-label' />
          <span>${current.newPrice}</span>
        </div>
        : <></>
      }
      <div className='card-title'>
        {name}
      </div>
      <div className='container flex-between'>
        <ul>
          {desc.map((item: any, i: number) => {
            return (
              <li key={i}>
                <span className="value">{item.value}</span>
              </li>
            )
          })}
        </ul>
        <div style={{position: 'relative'}}>
          <span className='price'>{(current && current?.price && `$${current.price}`) || `${t('plans.customprice')}`}</span>
          {
            current.newPrice !== 0 ?
              <img src="../../resources/img/discount.svg" alt="discount" className='discount-center'/>
            :
              <></>
          }
        </div>
      </div>
      <div className="flex-between container">
        <a 
          href={current && current?.link + `&language=${getCurrentLang()}&billingcycle=annually`} 
          className="purchase-vps"
          // onClick={event=>event.preventDefault()}
        >
          {t('plans.buyvps')}
        </a>
        <ul>
        {desc.map((item: any, i: number) => {
          return (
            <li className='flex-between' key={i}>
              <span className="label text-no-wrap">{item.label}</span>
            </li>
          )
        })}
        </ul>
      </div>
    </>
  )

  const fourthPositionPriceTemplate = (
    <>
      {
        current.newPrice !== 0 ?
        <div className='right-discount-label'>
          <img src="../../resources/img/discountLabel.png" alt='discount-label' />
          <span>${current.newPrice}</span>
        </div>
        : <></>
      }
      <div className='card-title'>
        {name}
      </div>
      <ul className='container'>
        {desc.map((item: any, i: number) => {
          return (
            <li className='flex-between' key={i}>
              <span className="label">{item.minLabel ? item.minLabel : item.label}</span>
              <span className="value">{item.value}</span>
            </li>
          )
        })}
      </ul>
      <div className="flex-between container overflow-hidden">
        <div style={{position: 'relative'}}>
          <span className='price'>{(current && current?.price && `$${current.price}`) || `${t('plans.customprice')}`}</span>
          {
            current.newPrice !== 0 ?
              <img src="../../resources/img/discount.svg" alt="discount" className='discount-center'/>
            :
              <></>
          }
        </div>
        <a 
          href={current && current?.link + `&language=${getCurrentLang()}&billingcycle=annually`} 
          className="purchase-vps"
          // onClick={event=>event.preventDefault()}
        >
          {t('plans.buyvps')}
        </a>
      </div>
    </>
  )

  return (
      <>
        {pricePosition===2&&thirdPositionPriceTemplate}
        {pricePosition===0&&firstPositionPriceTemplate}
        {pricePosition===1&&secondPositionPriceTemplate}
        {pricePosition===3&&fourthPositionPriceTemplate}
      </>
  );
}

export const RenderTabItems = ({location, t, tarifsCountries, setCurrentCountry, changeText}: any) => {
  const [country, setCountry] = useState({ value: en_countries.germany, index: 0 });

  const onClickCountry = (value: any, i: number, item: any) => {
    if(!item.disable) {
      setCountry({ value: value, index: i });
      setTimeout(()=>setCurrentCountry(value), 500)
      
      if(value!==country.value){
        changeText()
      }
    }
  }

  const getStepTransition=()=>{
    const lengthEnableCountries = tarifsCountries.filter((el:any)=>el.enable).length
    let stepsArray = []
    for(let i=0; i<lengthEnableCountries; i++){
      stepsArray.push(i*100)
    }
    return stepsArray
  }

  const stepTransition = getStepTransition();

  return (
      <>
        {tarifsCountries.map((item: any, i: number) => {
          return (
            <>
            {item.enable&&
              <div className={"tab-item "} key={i} onClick={() => onClickCountry(item.value, i, item)}>
                <div className={`tab-link ${i===country.index?'active':''} `}
                >
                  {item.title}
                </div>
              </div>
            }
            </>)
        })}
        <div className={('tab-item active')}
             style={
              {
                transform: `translate(${stepTransition[country.index]}%, 0px)`,
                width: `${Math.trunc(99/stepTransition.length)}%`,
              }
            }>
        </div>
      </>
  )
};

