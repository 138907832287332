import axios from 'axios';
import config from '../config';

const token = localStorage.getItem('token');

const h = {
  headers: { Authorization: `Bearer ${token}` }
};

const changePassword = (body: object) => {
  return axios.post(`${config.apiUrl}/changepass`, body, h)
    .then(({ data }) => {
      return data;
    });
};

export const settingsService = {
  changePassword
};