import { all, fork } from 'redux-saga/effects';
import { watchSources } from './components/Plans/saga';
import { watchMessages } from './components/Home/saga';
import { watchSettings } from './components/Order/saga';
import { watchFeedbacks } from './components/Feedback/saga';

export default function* main() {
  yield all([
    fork(watchSources),
    fork(watchMessages),
    fork(watchFeedbacks),
    fork(watchSettings)
  ]);
}
