import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Source } from '../../types';
import { Redirect } from 'react-router';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  t: any;
  location: any;
  history: any;
}

export const Terms = ({
  t,
  location,
  history,
}: Props) => {
  const terms = [
    {
      title: t('terms.title0'),
      desc: t('terms.desc0'),
      isSection: true
    },

    {
      title: t('terms.title1'),
      desc: t('terms.desc1'),
      isSection: true
    },
    {
      title: t('terms.title2'),
      desc: t('terms.desc2'),
      isSection: true
    },
    {
      title: t('terms.title3'),
      desc: t('terms.desc3'),
      isSection: false
    },
    {
      title: t('terms.title4'),
      desc: t('terms.desc4'),
      isSection: false
    },
    {
      title: t('terms.title5'),
      desc: t('terms.desc5'),
      isSection: false
    },
    {
      title: t('terms.title6'),
      desc: t('terms.desc6'),
      isSection: false
    },
    {
      title: t('terms.title7'),
      desc: t('terms.desc7'),
      isSection: false
    },
    {
      title: t('terms.title8'),
      desc: t('terms.desc8'),
      isSection: false
    },
    {
      title: t('terms.title9'),
      desc: t('terms.desc9'),
      isSection: false
    },
    {
      title: t('terms.title10'),
      desc: t('terms.desc10'),
      isSection: false
    },
    {
      title: t('terms.title11'),
      desc: t('terms.desc11'),
      isSection: false
    },
    {
      title: t('terms.title12'),
      desc: t('terms.desc12'),
      isSection: true
    },
    {
      title: t('terms.title13'),
      desc: t('terms.desc13'),
      isSection: false
    },
    {
      title: t('terms.title14'),
      desc: t('terms.desc14'),
      isSection: false
    },
    {
      title: t('terms.title15'),
      desc: t('terms.desc15'),
      isSection: false
    },
    {
      title: t('terms.title16'),
      desc: t('terms.desc16'),
      isSection: true
    },
    {
      title: t('terms.title17'),
      desc: t('terms.desc17'),
      isSection: false
    },
    {
      title: t('terms.title18'),
      desc: t('terms.desc18'),
      isSection: false
    },
    {
      title: t('terms.title19'),
      desc: t('terms.desc19'),
      isSection: false
    },
    {
      title: t('terms.title20'),
      desc: [t('terms.desc20-1'), t('terms.desc20-2'), t('terms.desc20-3'), t('terms.desc20-4'), t('terms.desc20-5'), t('terms.desc20-6'), t('terms.desc20-7'), t('terms.desc20-8'), t('terms.desc20-9')],
      isSection: true
    },
    
    
  ];

  const [current, setCurrent] = useState(terms[0]);

  useEffect(()=>{
    const urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams); 
    if(params.item&&params.item==='sale'){
      setCurrent(terms[20])
    }
    window.scrollTo({top:0})
  },[])
  return (
      <main>
        <div className="wrapper locations">
          <h1>{t('terms.ofuse')}</h1>

          <div className="terms">
            <div className="terms-list">
              {terms.map((term, i) => {
                if(!term.title){
                  return(
                    <div style={{height: '10px'}} />
                  )
                }
                return (
                  <li key={i} onClick={() => setCurrent(term)}
                      className={"term-list-item " + (current.title === term.title ? 'active ' : '') + (term.isSection ? 'section ' : '')}>
                    {term.title}
                  </li>
                )
              })}
            </div>
            <div className="terms-desc">
              <strong>{current.title}</strong>
              {Array.isArray(current.desc)?
                current.desc.map(el=><p>{el}</p>):
                <>
                  {current.desc}
                </>
              }
            </div>



            <div className="terms-desc-mobile">
              <strong>{t('terms.title0')}</strong>
              <p>{t('terms.desc0')}</p>
              <p></p>

              <strong>{t('terms.title1')}</strong>
              <p>{t('terms.desc1')}</p>
              <p></p>

              <strong>{t('terms.title2')}</strong>
              <p>{t('terms.desc2')}</p>
              <p></p>

              <strong>{t('terms.title3')}</strong>
              <p>{t('terms.desc3')}</p>
              <p></p>

              <strong>{t('terms.title4')}</strong>
              <p>{t('terms.desc4')}</p>
              <p></p>

              <strong>{t('terms.title5')}</strong>
              <p>{t('terms.desc5')}</p>
              <p></p>

              <strong>{t('terms.title6')}</strong>
              <p>{t('terms.desc6')}</p>
              <p></p>

              <strong>{t('terms.title7')}</strong>
              <p>{t('terms.desc7')}</p>
              <p></p>

              <strong>{t('terms.title8')}</strong>
              <p>{t('terms.desc8')}</p>
              <p></p>

              <strong>{t('terms.title9')}</strong>
              <p>{t('terms.desc9')}</p>
              <p></p>

              <strong>{t('terms.title10')}</strong>
              <p>{t('terms.desc10')}</p>
              <p></p>

              <strong>{t('terms.title11')}</strong>
              <p>{t('terms.desc11')}</p>
              <p></p>

              <strong>{t('terms.title12')}</strong>
              <p>{t('terms.desc12')}</p>
              <p></p>

              <strong>{t('terms.title13')}</strong>
              <p>{t('terms.desc13')}</p>
              <p></p>

              <strong>{t('terms.title14')}</strong>
              <p>{t('terms.desc14')}</p>
              <p></p>

              <strong>{t('terms.title15')}</strong>
              <p>{t('terms.desc15')}</p>
              <p></p>

              <strong>{t('terms.title16')}</strong>
              <p>{t('terms.desc16')}</p>
              <p></p>

              <strong>{t('terms.title17')}</strong>
              <p>{t('terms.desc17')}</p>
              <p></p>

              <strong>{t('terms.title18')}</strong>
              <p>{t('terms.desc18')}</p>
              <p></p>

              <strong>{t('terms.title20')}</strong>
              <p>{t('terms.desc20-1')}</p>
              <p>{t('terms.desc20-3')}</p>
              <p>{t('terms.desc20-4')}</p>
              <p>{t('terms.desc20-5')}</p>
              <p>{t('terms.desc20-6')}</p>
              <p>{t('terms.desc20-7')}</p>
              <p>{t('terms.desc20-8')}</p>
              <p>{t('terms.desc20-9')}</p>
              <p></p>

            </div>



          </div>
        </div>
      </main>
  );
};
