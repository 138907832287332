import React from 'react';
import ReactDOM, {hydrate, render} from 'react-dom';
import './index.css';
import { createBrowserHistory } from 'history';
import {
    Router
} from 'react-router-dom';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
//import { clarity } from 'react-microsoft-clarity';

const tagManagerArgs = {
    gtmId: 'GTM-THTWP84'
}
 
TagManager.initialize(tagManagerArgs);
//clarity.init('i2ox81fzds');

const history = createBrowserHistory();
const rootElement = document.getElementById("root");

/*ReactDOM.render(
  <React.StrictMode>
      <Router history={history}>
        <App />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);*/

if (rootElement?.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <Router history={history}>
        <App />
    </Router>
</React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <Router history={history}>
        <App />
    </Router>
</React.StrictMode>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
