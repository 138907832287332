import { put, takeLatest, all } from 'redux-saga/effects';
import {
  feedbackCommon,
  sendFeedbackSuccessAction,
  sendFeedbackErrorAction
} from './actions';
import { feedbackService } from '../../services';

function* sendFeedback(body: any) {
  try {
    const data = yield feedbackService.sendFeedback(body.data);
    yield put(sendFeedbackSuccessAction(data));
  } catch (e) {
    yield put(sendFeedbackErrorAction(e));
  }
}

export function* watchFeedbacks() {
  yield all([
    takeLatest(feedbackCommon.SEND_FEEDBACK_REQUEST, sendFeedback),
  ]);
}
