import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Source } from '../../types';
import { Redirect } from 'react-router';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  t: any;
  location: any;
  history: any;
}

const { Panel } = Collapse;

export const Faq = ({
  t,
  location,
  history,
}: Props) => {
  const terms = [
    {
      title: t('faq.title2'),
      desc: t('faq.desc1')
    },

    {
      title: t('faq.title3'),
      desc: t('faq.desc2')
    },
    {
      title: t('faq.title4'),
      desc: t('faq.desc3')
    },
    {
      title: t('faq.title5'),
      desc: t('faq.desc4')
    },
    {
      title: t('faq.title6'),
      desc: t('faq.desc5')
    },
    {
      title: t('faq.title7'),
      desc: t('faq.desc6')
    },

    {
      title: t('faq.title8'),
      desc: t('faq.desc7')
    },
    {
      title: t('faq.title9'),
      desc: t('faq.desc8')
    },
    {
      title: t('faq.title10'),
      desc: t('faq.desc9')
    },
    {
      title: t('faq.title11'),
      desc: t('faq.desc10')
    },
    {
      title: t('faq.title12'),
      desc: t('faq.desc11')
    },
    
  ];

  const [current, setCurrent] = useState('1');

  return (
      <main>
        <div className="wrapper locations">
          <h1>{t('faq.title1')}</h1>

          <Collapse className='accordion' bordered={false} accordion onChange={(key) => {if(key) {setCurrent(Array.isArray(key) ? key[0] : key)}}} activeKey={[current]}>
              {terms.map((term, i) => {
                return (
                  <Panel header={term.title} className={i === terms.length-1 ? 'last-item' : ''} key={i+1}>
                    <p>{term.desc}</p>
                  </Panel>
                )
              })}
              </Collapse>
        </div>
      </main>
  );
};
