import { BaseAction } from '../../types';

export const feedbackCommon = {
  SEND_FEEDBACK_REQUEST: 'SEND_FEEDBACK_REQUEST',
  SEND_FEEDBACK_SUCCESS: 'SEND_FEEDBACK_SUCCESS',
  SEND_FEEDBACK_ERROR: 'SEND_FEEDBACK_ERROR',
};

export const sendFeedbackRequestAction = (data: {}): BaseAction => ({
  type: feedbackCommon.SEND_FEEDBACK_REQUEST,
  data
});

export const sendFeedbackSuccessAction = (data: []): BaseAction => ({
  type: feedbackCommon.SEND_FEEDBACK_SUCCESS,
  data
});

export const sendFeedbackErrorAction = (error: {}): BaseAction => ({
  type: feedbackCommon.SEND_FEEDBACK_ERROR,
  data: error
});
