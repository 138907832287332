import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from './component';

interface Props {
  hideItems?: boolean;
}

const HeaderContainer = ({ hideItems }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <Header
      t={t}
      location={location}
      history={history}
      i18n={i18n}
      hideItems={hideItems}
    />
  );
};

export { HeaderContainer as Header };
