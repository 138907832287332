import { put, takeLatest, all } from 'redux-saga/effects';
import {
  settingsCommon,
  changePwdSuccessAction,
  changePwdErrorAction
} from './actions';
import { settingsService } from '../../services';

function* changePassword(body: any) {
  try {
    const data = yield settingsService.changePassword(body.data);
    yield put(changePwdSuccessAction(data));
  } catch (e) {
    yield put(changePwdErrorAction(e));
  }
}

export function* watchSettings() {
  yield all([
    takeLatest(settingsCommon.CHANGE_PWD_REQUEST, changePassword),
  ]);
}
