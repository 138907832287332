import { combineReducers } from 'redux';
import { sources } from './components/Plans/reducer';
import { messages, tags } from './components/Home/reducer';
import { settings } from './components/Order/reducer';
import { feedback } from './components/Feedback/reducer';

export default combineReducers({
  sources,
  messages,
  settings,
  tags,
  feedback
});
