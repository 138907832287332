import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Plans } from './component';
import {
  removeSourceRequestAction,
  sourcesRequestAction,
  updateSourceEnablingRequestAction,
  editSourceRequestAction
} from './actions';

const PlansContainer = () => {
  const location = useLocation(),
        dispatch = useDispatch(),
        history = useHistory(),
        sources = useMappedState((state: any) => state.sources);

    const { t, i18n } = useTranslation();

  return (
    <Plans
      location={location}
      history={history}
      sources={sources}
      t={t}
      i18n={i18n}
      getSources={() => { dispatch(sourcesRequestAction()) }}
      updateSourceEnabling={(id: string, body: any) => { dispatch(updateSourceEnablingRequestAction({id, body})) }}
      removeSource={(id: string) => { dispatch(removeSourceRequestAction(id)) }}
      editSource={(id: string, body: any) => { dispatch(editSourceRequestAction({id, body})) }}
    />
  );
};

export { PlansContainer as Plans };
