import React, {useEffect, useCallback} from 'react';
import { StoreContext } from 'redux-react-hook';
import { createBrowserHistory } from 'history';
import {
  Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Home } from './components/Home';
import { Plans } from './components/Plans';
import { MainLayoutContainer } from './components/MainLayout';
import { Payment } from './components/Payment';
import { Locations } from './components/Locations';
import { Terms } from './components/Terms';
import { Faq } from './components/Faq';
// @ts-ignore
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import store from './store/index';
import './App.css';
import Cookies from 'universal-cookie';
import { Partners } from './components/Partners';
import { clarity } from 'react-microsoft-clarity';
import ComparisonOfPlans from './components/ComparisonOfPlans/ComparisonOfPlans';

const cookies = new Cookies();
const history = createBrowserHistory();

const App = () => {
  const languageDetection = () : string => {
    let langs = navigator.languages;
    let lcls = [
      {
        locale: "ru-ru",
        definition: 'ru'
      },
      {
        locale: "ru-mo",
        definition: 'ru'
      },
      {
        locale: "ru-ua",
        definition: 'ru'
      },
      {
        locale: "ru",
        definition: 'ru'
      },
      {
        locale: "en-029",
        definition: 'en'
      },
      {
        locale: "en-au",
        definition: 'en'
      },
      {
        locale: "en-bz",
        definition: 'en'
      },
      {
        locale: "en-ca",
        definition: 'en'
      },
      {
        locale: "en-cb",
        definition: 'en'
      },
      {
        locale: "en-gb",
        definition: 'en'
      },
      {
        locale: "en-ie",
        definition: 'en'
      },
      {
        locale: "en-in",
        definition: 'en'
      },
      {
        locale: "en-jm",
        definition: 'en'
      },
      {
        locale: "en-mt",
        definition: 'en'
      },
      {
        locale: "en-my",
        definition: 'en'
      },
      {
        locale: "en-nz",
        definition: 'en'
      },
      {
        locale: "en-ph",
        definition: 'en'
      },
      {
        locale: "en-sg",
        definition: 'en'
      },
      {
        locale: "en-tt",
        definition: 'en'
      },
      {
        locale: "en-us",
        definition: 'en'
      },
      {
        locale: "en-za",
        definition: 'en'
      },
      {
        locale: "en-zw",
        definition: 'en'
      },
      {
        locale: "en",
        definition: 'en'
      },
      {
        locale: "uk-ua",
        definition: 'uk'
      },
      {
        locale: "uk",
        definition: 'uk'
      },
      {
        locale: "de",
        definition: 'de'
      },
      {
        locale: "de-at",
        definition: 'de'
      },
      {
        locale: "de-ch",
        definition: 'de'
      },
      {
        locale: "de-de",
        definition: 'de'
      },
      {
        locale: "de-li",
        definition: 'de'
      },
      {
        locale: "de-lu",
        definition: 'de'
      },
      {
        locale: "fr",
        definition: 'fr'
      },
      {
        locale: "fr-mc",
        definition: 'fr'
      },
      {
        locale: "fr-lu",
        definition: 'fr'
      },
      {
        locale: "fr-fr",
        definition: 'fr'
      },
      {
        locale: "fr-ch",
        definition: 'fr'
      },
      {
        locale: "fr-ca",
        definition: 'fr'
      },
      {
        locale: "fr-be",
        definition: 'fr'
      },
    ]

    let locale = undefined;
    for(let loc of langs) {
      let tmpLoc = undefined;
      for(let known of lcls) {
        if(loc.toLowerCase() === known.locale) {
          tmpLoc = known.definition;
          break;
        }
      }
      if(tmpLoc) {
        locale = tmpLoc;
        break;
      }
    }
    if(!locale) {
      locale = 'en';
    }
    return locale;
  }
  const {i18n} = useTranslation();
  useEffect(() => {
    i18n.init({
      debug: false
    })
    if(localStorage.getItem('lng') !== null) {
      let lang = localStorage.getItem('lng')!;
      i18n.changeLanguage(lang)
        .then(() => {
          localStorage.setItem('lng', i18n.language ? i18n.language : 'en');
        });
    }
    else {
      let lang = languageDetection();
      i18n.changeLanguage(lang)
        .then(() => {
          localStorage.setItem('lng', i18n.language ? i18n.language : 'en');
        });
    }
    
  }, []);

  const handleLanguageChanged = useCallback(() => {
    localStorage.setItem('lng', i18n.language ? i18n.language : 'en');
  }, []);

  /*useEffect(() => {
    clarity.init('i2ox81fzds');
  }, []);*/

  useEffect(() => {
      i18n.on('languageChanged', handleLanguageChanged);
      return () => {
          i18n.off('languageChanged', handleLanguageChanged);
      };
  }, [handleLanguageChanged]);
  
  const location = useLocation();

  return (
      <StoreContext.Provider value={store}>
        <Route>
          <MainLayoutContainer>
            <TransitionGroup>
              <CSSTransition timeout={450} classNames="fade" key={location.key}>
                <Switch location={location}>
                  <Redirect exact from='/' to='/home' />
                  <Route path='/home' component={Home} />
                  <Route path='/shop' component={Plans} />
                  <Route path='/comparison-of-plans' component={ComparisonOfPlans} />
                  <Route exact path='/payment' component={Payment} />
                  <Route path='/locations' component={Locations} />
                  <Route path='/terms' component={Terms} />
                  <Route path='/faq' component={Faq}/>
                  <Route path='/partners' component={Partners}/>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
            <Switch location={location}>
              <Route exact path='/payment/cards' component={Payment} />
              <Route exact path='/payment/wallets' component={Payment} />
              <Route exact path='/payment/crypto' component={Payment} />
            </Switch>
          </MainLayoutContainer>
        </Route>

        <Route path="*" />
      </StoreContext.Provider>
  );
}

export default App;
