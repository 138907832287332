import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Textra from 'react-textra';
interface Props {
  location: any;
  history: any;
  t: any;
  i18n: any;
  hideItems?: boolean
}

interface MenuProps {
  location: any;
  t: any;
  onClose(): void;
}

export const Header = ({
  location,
  history,
  t,
  i18n,
  hideItems = false
}: Props) => {
  const [open, setOpen] = useState(false);
  const [defLang, setDefLang] = useState<string | undefined>(undefined);

  useEffect(() => {
    setDefLang(i18n.language);
  }, [i18n.language])

  const handleLangChange = (e: any) => {
    const targetValue = e;

    i18n.changeLanguage(targetValue);
    localStorage.setItem('lng', targetValue);
  };

  const goToSettings = () => {
    // history.push('/settings');
    closeMenu();
  };

  const closeMenu = () => {
    setOpen(!open);
  };

  const paymentUrlLanguage = {
    'ru': 'russian',
    'en': 'english',
    'it': 'italian',
    'fr': 'french',
    'de': 'german',
    'spa': 'spanish',
    'tu': 'turkish',
    'ua': 'ukranian'
  };

  const getCurrentLang = () => {
    // @ts-ignore
    return paymentUrlLanguage[localStorage.getItem('lng') || 'en'];
  }

  return (
    <>
    {/*<Promo location={location} t={t} onClose={closeMenu}/>*/}
    <Promo location={location} t={t} onClose={closeMenu}/>
    <header style={{zIndex: 999}}>
      <div className="wrapper header">
        <div className="left-part-of-header">
          <div className="logo">
            <a href="/">
              <img src="../resources/img/header_logo.svg" alt="logo" className="logo-header"/>
            </a>
          </div>

          {!hideItems && (
            <>
              <div className="menu">
              {/* <Select id="lang" className="ant-select" value={defLang ? defLang : 'en'} onChange={handleLangChange}>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="ru">Русский</Select.Option>
                <Select.Option value="de">Deutsch</Select.Option>
                <Select.Option value="uk">Українська</Select.Option>
                <Select.Option value="fr">Français</Select.Option>
              </Select> */}
                <RenderLinks location={location} t={t} onClose={closeMenu}/>
              </div>
            </>
          )}
        </div>

        {!hideItems && (
          <div className='right-part-of-header'>
            <input className="menu-btn" type="checkbox" id="menu-btn" checked={open} onChange={() => setOpen(!open)}/>
          
            <div className="menu-action">
              <a href={`https://billing.net-ray.com/index.php?rp=/login&language=${getCurrentLang()}`} className="login">{t('header.login')}</a>
            </div>
            <div className="menu-action">
              <Link to="/shop" className="buynow">{t('header.buynow')}</Link>
            </div>

            {/* <ul className={`menu-mobile ${!open ? 'hide-menu' : 'show-menu'}`}>
              <RenderLinks location={location} t={t} onClose={closeMenu}/>
              <li><Link to="/faq" className="nav-item" onClick={() => goToSettings()}>{t('footer.faq')}</Link></li> 
              <li><Link to="/terms" className="nav-item" onClick={() => goToSettings()}>{t('footer.terms')}</Link></li> 
              <li><a href={`https://billing.net-ray.com/index.php?rp=/login&language=${getCurrentLang()}`} className="buynow-mobile" onClick={() => goToSettings()}>{t('header.login')}</a></li>  
              <li><Link to="/plans" className="buynow-mobile" onClick={() => goToSettings()}>{t('header.buynow')}</Link></li>              
            </ul>  */}
          </div>
        )}
      </div>
      {!hideItems && (
      
        <ul className={`menu-mobile ${open ? 'is-open-menu' : 'is-close-menu'}`}>
          <RenderLinks location={location} t={t} onClose={closeMenu}/>
          <li><Link to="/faq" className="nav-item" onClick={() => goToSettings()}>{t('footer.faq')}</Link></li> 
          <li><Link to="/terms" className="nav-item" onClick={() => goToSettings()}>{t('footer.terms')}</Link></li> 
          <li><a href={`https://billing.net-ray.com/index.php?rp=/login&language=${getCurrentLang()}`} className="buynow-mobile" onClick={() => goToSettings()}>{t('header.login')}</a></li>  
          <li><Link to="/shop" className="buynow-mobile" onClick={() => goToSettings()}>{t('header.buynow')}</Link></li>              
        </ul> 

      )}
      <div className="cross menu--1">
        <label htmlFor="menu-btn">
          <input className="menu-btn" type="checkbox" id="menu-btn" style={{display: 'none'}} checked={open} onChange={() => setOpen(!open)}/>
          <svg id='menusvg' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="30"/>
            <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35"/>
            <path className="line--2" d="M0 50h70"/>
            <path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65"/>
          </svg>
        </label>
      </div>
    </header>
    </>
  );
};

export const RenderLinks = ({location, t, onClose}: MenuProps) => {
  const links = [
    {
      title: t('header.menu.home'),
      external_link: false,
      url: 'home'
    },
    {
      title: t('header.menu.plans'),
      external_link: false,
      url: 'shop'
    },
    {
      title: t('header.menu.payments'),
      external_link: false,
      url: 'payment/cards',
    },
    // {
    //   title: t('header.menu.vpn'),
    //   external_link: true,
    //   url: 'https://vpn.net-ray.com/',
    // },
// {
//   title: 'VPN',
//  external_link: true,
//  url: 'https://press-vpn.net/'
//}
  ];

  const isActivePath = (url: string) => {
    return location.pathname.includes(url);
  }

  return (
    <>
      {links.map((link, i) => {
        return (
          <li className="nav-item" key={i}>
            <Link className={"nav-link " + (isActivePath(link.url) ? 'active' : '')}
              to={link.external_link ? {pathname: link.url } : '/' + link.url}
              target={link.external_link ? "_blank" : '_self'}
              onClick={() => onClose()}
            >
              {link.title}
            </Link>
          </li>
        )
      })}
    </>
  )
};

const Promo = ({location, t, onClose}: MenuProps) => {
  const getTexts = (count: number) => {
    let texts: string[] = [];
    for(let i = 0; i < count; ++i) {
      texts.push(t(`promos.text_${i}`))
    }
    return texts;
  }

  return (
    <div className="header">
      <a 
        style={{background: 'linear-gradient(90deg, rgba(255,53,53,1) 0%, rgba(184,33,33,1) 100%)', lineHeight: '30px', color: '#fff', textAlign: 'center', width: '100%', padding: 0}}
        href='https://billing.net-ray.com/index.php/store/vps/minimal'
      >
          <Textra effect='rightLeft' data={getTexts(2)}/>
      </a>
    </div>
  )
};
