import { settingsCommon } from './actions';
import { BaseAction, InitialState } from '../../types';

const initialState: InitialState = {
  data: [],
  loading: false
};

export const settings = (state = initialState, action: BaseAction) => {
  switch (action.type) {
    case settingsCommon.CHANGE_PWD_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case settingsCommon.CHANGE_PWD_SUCCESS:
      return {
        data: action.data,
        loading: false
      };

    default:
      return state;
  }
};
