import { sourcesCommon } from './actions';
import { BaseAction, InitialState } from '../../types';

const initialState: InitialState = {
  data: [],
  meta: {},
  loading: false
};

export const sources = (state = initialState, action: BaseAction) => {
  switch (action.type) {
    case sourcesCommon.SOURCES_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case sourcesCommon.SOURCES_SUCCESS:
      return {
        data: action.data,
        loading: false
      };

    case sourcesCommon.ADD_SOURCE_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case sourcesCommon.ADD_SOURCE_SUCCESS:
      return {
        data: [
          ...state.data,
          {
            Url: action.data.data.url,
            Enabled: true,
          }
        ],
        meta: action.data.meta,
        loading: false
      };

    case sourcesCommon.ADD_SOURCE_ERROR:

      return {
        data: state.data,
        meta: action.data,
        loading: false
      };

    case sourcesCommon.UPDATE_SOURCE_ENABLING_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case sourcesCommon.UPDATE_SOURCE_ENABLING_SUCCESS:
      const index = state.data.findIndex((s: any) => s.SourceID === action.data.id);

      return {
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            Enabled: action.data.enabled || action.data.body.enabled
          },
          ...state.data.slice(index + 1),
        ],
        loading: false
      };

    case sourcesCommon.REMOVE_SOURCE_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case sourcesCommon.REMOVE_SOURCE_SUCCESS:
      const result = state.data.filter((s: any) => action.data !== s.SourceID);

      return {
        data: result,
        loading: false
      };

    case sourcesCommon.EDIT_SOURCE_REQUEST:
      return {
        data: state.data,
        loading: true
      };

    case sourcesCommon.EDIT_SOURCE_SUCCESS:
      const i = state.data.findIndex((s: any) => s.SourceID === action.data.id);

      return {
        data: [
          ...state.data.slice(0, i),
          {
            ...state.data[i],
            Url: action.data.body.url,
            Category: action.data.body.category,
            Tags: action.data.body.tags
          },
          ...state.data.slice(i + 1),
        ],
        loading: false
      };

    default:
      return state;
  }
};
