import axios from 'axios';
import config from '../config';

const token = localStorage.getItem('token');

const h = {
  headers: { Authorization: `Bearer ${token}` }
};

const getSources = () => {
  return axios.get(`${config.apiUrl}/sources/list`, h)
    .then(({ data }) => {
      return data;
    });
};

const addSource = (body: object) => {
  return axios.post(`${config.apiUrl}/sources/add`, body, h)
    .then(({ data }) => {
    return data;
  });
};

const updateSourceEnabling = (id: string, body: object) => {
  return axios.post(`${config.apiUrl}/sources/${id}/switch`, body, h)
    .then(({ data }) => {
    return data;
  });
};

const removeSource = (id: string) => {
  return axios.get(`${config.apiUrl}/sources/${id}/delete`, h)
    .then(({ data }) => {
      return data;
    });
};

const editSource = (id: string, body: object) => {
  return axios.post(`${config.apiUrl}/sources/${id}/edit`, body, h)
    .then(({ data }) => {
      return data;
    });
};

export const sourcesService = {
  getSources,
  addSource,
  updateSourceEnabling,
  removeSource,
  editSource
};
