import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Footer } from './component';

interface Props {
  setVisibility(visibility: boolean): void;
}

const FooterContainer = ({
  setVisibility
}: Props) => {
  const location = useLocation();

  const { t, i18n } = useTranslation();

  return (
    <Footer
      location={location}
      t={t}
      i18n={i18n}
      setVisibility={setVisibility}
    />
  );
};

export { FooterContainer as Footer };
