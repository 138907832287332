import axios from 'axios';
import config from '../config';

const sendFeedback = (body: object) => {
    return axios.post(`${config.feedbackUrl}/feedback`, body)
        .then(({ data }) => {
            return data;
        });
};

export const feedbackService = {
    sendFeedback
};
