import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './styles.module.css';

interface Props {
  t: any;
  location: any;
  history: any;
  messages: any;
  tags: any;
  getMessages(): void;
  getTags(): void;
  searchMessages(obj: object): void;
}

export const Home = ({
  t,
  location,
  history,
  messages,
  tags,
  getMessages,
  getTags,
  searchMessages
}: Props) => {
  const goToPlans = () => {
    return history.push('/shop');
  }

  return (
    <main>
      <div className="wrapper home">
        <h1>{t('home.title1')}</h1>
        <div className='sub-title-group'>
          {String(t('home.title2')).split('').map((el,index)=>{
            if(el==='i' || el==='і'){
              return(
                <div style={{position:'relative'}} key={index} >
                  <div className='i-dot'>.</div>
                  <h2>ı</h2>
                </div>)
            }
            return(<h2 key={index}>{el}</h2>)
          })}
        </div>
        <h4>{t('home.subtitle')}</h4>
        <button className="trybtn" onClick={() => goToPlans()}>{t('home.tryforfree')}</button>

        <div className="content">
          <div className="left-cont">
            <div className="title">
              {t('home.easytouse')}
            </div>
            <div className="desc">
              {t('home.easytouseDesc')}
            </div>
          </div>


          <div className="left-cont">
             <div className="title">
              {t('home.highspeed')}
            </div>
            <div className="desc">
              {t('home.highspeedDesc')}
            </div>

          </div>
          <div className="left-cont">
            <div className="title">
              {t('home.confidential')}
            </div>
            <div className="desc">
              {t('home.confidentialDesc')}
            </div>
          </div>

          <div className="left-cont">
          <div className="title">
              {t('home.anonymous')}
            </div>
            <div className="desc">
              {t('home.anonymousDesc')}
            </div>

          </div>
        </div>
      </div>
    </main>
  );
};
