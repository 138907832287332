import axios from 'axios';
import config from '../config';

const token = localStorage.getItem('token');

const h = {
  headers: { Authorization: `Bearer ${token}` }
};


const getTags = () => {
  return axios.get(`${config.apiUrl}/tags/list`, h)
    .then(({ data }) => {
      return data;
    });
};

const getMessages = () => {
  return axios.get(`${config.apiUrl}/telegram-messages`, h)
    .then(({ data }) => {
      return data;
    });
};

const searchMessages = (body: object) => {
  return axios.post(`${config.apiUrl}/message/search`, body, h) //'content-type': 'application/x-www-form-urlencoded'
    .then(({ data }) => {
      return data;
    });
};

export const messagesService = {
  getTags,
  getMessages,
  searchMessages
};
