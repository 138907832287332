import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Source } from '../../types';
import { Redirect } from 'react-router';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';

const { Panel } = Collapse;

interface Props {
  t: any;
  location: any;
  history: any;
}

export const Payment = ({
  t,
  location,
  history,
}: Props) => {
  return (
      <main>
        <div className="wrapper payment">
          <h1>{t('payment.title')}</h1>

          <div className="content">
            <Tab t={t} location={location}/>
          </div>
        </div>
      </main>
  );
};

export const Tab = ({location, t}: any) => {
  const items = [
    {
      title: t('payment.bankcards'),
      url: 'cards',
      component: <BankCardsComponent t={t}/>
    },
    {
      title: t('payment.ewallets'),
      url: 'wallets',
      component: <WalletComponent t={t}/>
    },
    {
      title: t('payment.crypto'),
      url: 'crypto',
      component: <CryptoComponent t={t}/>
    }
  ];

  const paths = location.pathname.split('/')
  const path = paths[paths.length - 1];
  const current = items.find((item, key)  => (item.url === path)) || null;

  return (
    <>
      <div className="header">
        <h3 className="subtitle">{t('payment.subtitle')}</h3>
        <div className="tabs">
          <RenderTabItems location={location} items={items} t={t} />
        </div>
      </div>
      <div className="details">
        {current && current.component}
      </div>
    </>
  );
}

export const RenderTabItems = ({location, items, t}: any) => {

  const paths = location.pathname.split('/')
  const path = paths[paths.length - 1];
  const currentIndex = items.map((el:any)=>el.url).indexOf(path)

  return (
    <>
      <div className='tab-item active slider' style={{left: `${currentIndex*32.5}%` }}/>
      {items.map((item: any, i: number) => {
        return (
          <div className={"tab-item "} key={i}>
            <Link className={"tab-link " + (path === item.url ? 'active' : '')}
                  to={`/payment/${item.url}`}
            >
              {item.title}
            </Link>
          </div>
        )
      })}
    </>
  )
}; 

export const WalletComponent = ({
    t,
  }: any) => {
    return (
      <>
        <div className="service-block">
          {/* <div className="item">
                <img src="../resources/img/gg-bank.svg"/>
              </div> */}
          <div className="item">
            <img src="../resources/img/pm.svg" />
          </div>
          <div className="item">
            <img src="../resources/img/nix.svg" />
          </div>
          <div className="item">
            <img src="../resources/img/a-group.svg" />
          </div>
          {/* <div className="item">
                <img src="../resources/img/currencies.svg"/>
              </div> */}
        </div>

        <div className="desc-block">
          {t('payment.ewalletsDesc')}
        </div>
      </>
    );
  };

export const BankCardsComponent = ({
   t,
 }: any) => {
  return (
    <>
      <div className="service-block">
        <div className="item">
          <img src="../resources/img/3ds.svg"/>
        </div>
        <div className="item">
          <img src="../resources/img/mastercard.svg"/>
        </div>
        <div className="item">
          <img src="../resources/img/visa.svg"/>
        </div>
        <div className="item">
          <img src="../resources/img/Apple_Pay_logo.svg"/>
        </div>
        <div className="item">
          <img src="../resources/img/Google_Pay_Logo.svg"/>
        </div>
      </div>

      <div className="desc-block">
        {t('payment.bankcardsDesc')}
      </div>
    </>
  );
};

export const CryptoComponent = ({
 t,
}: any) => {
  return (
    <>
      <div className="service-block">
        <div className="brick">
          <div className="item">
            <img src="../resources/img/btc.svg"/>
          </div>
          <div className="item">
            <img src="../resources/img/btc-cash.svg"/>
          </div>
        </div>

        <div className="brick">
          <div className="item">
            <img src="../resources/img/ltc.svg"/>
          </div>
          <div className="item">
            <img src="../resources/img/dash.svg"/>
          </div>
        </div>

        <div className="brick">
          <div className="item">
            <img src="../resources/img/zcash.svg"/>
          </div>
          <div className="item">
            <img src="../resources/img/rpx.svg"/>
          </div>
        </div>

        <div className="brick">
          <div className="item">
            <img src="../resources/img/usdtg.svg"/>
          </div>
          <div className="item">
            <img src="../resources/img/usdt.svg"/>
          </div>
        </div>

        <div className="brick">
          <div className="item">
            <img src="../resources/img/bnb.svg"/>
          </div>
          <div className="item">
            <img src="../resources/img/doge.svg"/>
          </div>
        </div>
      </div>

      <div className="desc-block">
        {t('payment.cryptoDesc')}
      </div>
    </>
  );
};
