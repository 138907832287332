import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Feedback } from './component';
import { sendFeedbackRequestAction } from './actions';

interface Props {
  getVisibility(): boolean;
  setVisibility(visibility: boolean): void;
}

const FeedbackContainer = ({
                             getVisibility,
                             setVisibility
                           }: Props) => {
  const location = useLocation(),
        dispatch = useDispatch(),
        history = useHistory();

    const { t } = useTranslation(),
      feedback = useMappedState((state: any) => state.feedback);


  return (
    <Feedback
      location={location}
      history={history}
      t={t}
      feedback={feedback}
      getVisibility={getVisibility}
      setVisibility={setVisibility}
      sendFeedback={(data) => { dispatch(sendFeedbackRequestAction(data)) }}
    />
  );
};

export { FeedbackContainer as Feedback };
