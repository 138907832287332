import { BaseAction } from '../../types';

export const sourcesCommon = {
  SOURCES_REQUEST: 'SOURCES_REQUEST',
  SOURCES_SUCCESS: 'SOURCES_SUCCESS',
  SOURCES_ERROR: 'SOURCES_ERROR',

  ADD_SOURCE_REQUEST: 'ADD_SOURCE_REQUEST',
  ADD_SOURCE_SUCCESS: 'ADD_SOURCE_SUCCESS',
  ADD_SOURCE_ERROR: 'ADD_SOURCE_ERROR',

  UPDATE_SOURCE_ENABLING_REQUEST: 'UPDATE_SOURCE_ENABLING_REQUEST',
  UPDATE_SOURCE_ENABLING_SUCCESS: 'UPDATE_SOURCE_ENABLING_SUCCESS',
  UPDATE_SOURCE_ENABLING_ERROR: 'UPDATE_SOURCE_ENABLING_ERROR',

  REMOVE_SOURCE_REQUEST: 'REMOVE_SOURCE_REQUEST',
  REMOVE_SOURCE_SUCCESS: 'REMOVE_SOURCE_SUCCESS',
  REMOVE_SOURCE_ERROR: 'REMOVE_SOURCE_ERROR',

  EDIT_SOURCE_REQUEST: 'EDIT_SOURCE_REQUEST',
  EDIT_SOURCE_SUCCESS: 'EDIT_SOURCE_SUCCESS',
  EDIT_SOURCE_ERROR: 'EDIT_SOURCE_ERROR',
};

export const sourcesRequestAction = (): BaseAction => ({
  type: sourcesCommon.SOURCES_REQUEST
});

export const sourcesSuccessAction = (data: []): BaseAction => ({
  type: sourcesCommon.SOURCES_SUCCESS,
  data
});

export const sourcesErrorAction = (error: {}): BaseAction => ({
  type: sourcesCommon.SOURCES_ERROR,
  data: error
});

export const addSourceRequestAction = (data: any): BaseAction => ({
  type: sourcesCommon.ADD_SOURCE_REQUEST,
  data
});

export const addSourceSuccessAction = (data: {}): BaseAction => ({
  type: sourcesCommon.ADD_SOURCE_SUCCESS,
  data
});

export const addSourceErrorAction = (error: {}): BaseAction => ({
  type: sourcesCommon.ADD_SOURCE_ERROR,
  data: error
});

export const updateSourceEnablingRequestAction = (data: any): BaseAction => ({
  type: sourcesCommon.UPDATE_SOURCE_ENABLING_REQUEST,
  data
});

export const updateSourceEnablingSuccessAction = (data: []): BaseAction => ({
  type: sourcesCommon.UPDATE_SOURCE_ENABLING_SUCCESS,
  data
});

export const updateSourceEnablingErrorAction = (error: {}): BaseAction => ({
  type: sourcesCommon.UPDATE_SOURCE_ENABLING_ERROR,
  data: error
});

export const removeSourceRequestAction = (data: any): BaseAction => ({
  type: sourcesCommon.REMOVE_SOURCE_REQUEST,
  data
});

export const removeSourceSuccessAction = (data: []): BaseAction => ({
  type: sourcesCommon.REMOVE_SOURCE_SUCCESS,
  data
});

export const removeSourceErrorAction = (error: {}): BaseAction => ({
  type: sourcesCommon.REMOVE_SOURCE_ERROR,
  data: error
});

export const editSourceRequestAction = (data: any): BaseAction => ({
  type: sourcesCommon.EDIT_SOURCE_REQUEST,
  data
});

export const editSourceSuccessAction = (data: []): BaseAction => ({
  type: sourcesCommon.EDIT_SOURCE_SUCCESS,
  data
});

export const editSourceErrorAction = (error: {}): BaseAction => ({
  type: sourcesCommon.EDIT_SOURCE_ERROR,
  data: error
});
